import { NextPage } from 'next'
import { useEffect } from 'react'
import { useRouter } from 'next/router'

import { useAppSelector } from '@closer/redux-storage'

import { LoadingSpinner } from '../components/Common'

const IndexPage: NextPage = () => {
    const router = useRouter()
    const tenantUser = useAppSelector(state => state.closerUser.tenantUser)

    useEffect(() => {
        const { matrixAccount } = tenantUser || {}

        if (tenantUser === null) {
            router.push('/login')
        }

        if (matrixAccount && !matrixAccount.integration.whatsapp) {
            router.push('/register/scan')
        }

        if (tenantUser) {
            router.push('/app')
        }
    }, [router, tenantUser])

    if (tenantUser === undefined) {
        return <LoadingSpinner />
    }

    return null
}

export default IndexPage
